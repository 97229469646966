<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">Edit Privacy Policy</h6>
      </b-row>
    </b-card>
    <div>
      <b-form ref="form">
        <!-- Row Loop -->
        <div class="pb-2">
          <b-card>
            <b-row>
              <!-- page title -->
              <b-col md="12">
                <b-form-group label="Title" label-for="page-title">
                  <b-form-input id="page-title" type="text" v-model="page.title" placeholder="Page Title" />
                </b-form-group>
              </b-col>

              <!-- Content -->
              <b-col md="12">
                <b-form-group label="Content" label-for="page-description">
                  <quill-editor id="blog-content" v-model="page.content" />
                </b-form-group>
              </b-col>
              <!-- save Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="success"
                  class="mt-0 mt-md-2"
                  block
                  @click="saveData()"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BCard,
  BFormTextarea,
  BLink
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';

import Ripple from 'vue-ripple-directive';

import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BButton,
    BFormTextarea,
    quillEditor,
    BLink,
    BFormGroup,
    BFormInput
  },
  directives: {
    Ripple
  },
  data() {
    return {
      page: []
    };
  },
  methods: {
    async saveData() {
      await useJwt
        .patch_page('1', {
          title: this.page.title,
          content: this.page.content
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Page updated`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully updated page.`
            }
          });
        })
        .catch(e => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error: 1`,
              icon: 'CropIcon',
              variant: 'danger',
              text: `We couldn't update page data to the server.`
            }
          });
        });
    }
  },
  created() {
    useJwt
      .get_page('1')
      .then(response => {
        this.page = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CropIcon',
            variant: 'danger',
            text: `We couldn't downlad your page data from the server.`
          }
        });
      });
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>